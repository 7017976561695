import { Controller } from "@hotwired/stimulus";
import { isEnterKey } from "../../utils/inputs_utils";
import { appendUrlSearchParams } from "../../utils/misc";

export default class extends Controller {
  static targets = ["form", "emailInput", "defaultInputsContainer", "otpInput", "otpInputContainer"];
  static values = { verifyOtpUrl: String };

  catchEnterKeyPress(e) {
    if (isEnterKey(e.keyCode)) {
      this.verifyOtp(e);
    }
  }

  verifyOtp(e) {
    e.preventDefault();

    const isFormValid = $(this.formTarget).parsley().validate();
    if (!isFormValid) {
      return;
    }

    const url = this._buildVerifyOtpUrl(this.verifyOtpUrlValue);
    fetch(url, {
      method: "get",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.otp_required) {
          this._displayOtpInput();
        } else {
          this.formTarget.submit();
        }
      });
  }

  _buildVerifyOtpUrl(baseUrl) {
    return appendUrlSearchParams(baseUrl, { email: this.emailInputTarget.value });
  }

  _displayOtpInput() {
    this.otpInputTarget.required = true;
    this.defaultInputsContainerTarget.classList.add("hidden");
    this.otpInputContainerTarget.classList.remove("hidden");
  }
}
