import { Controller } from "@hotwired/stimulus";
import swal from "sweetalert";
import { isSwalConfirmed } from "../utils/misc";

export default class extends Controller {
  connect() {
    const { confirm, confirmButtonText = "Oui", cancelButtonText = "Non" } = this.element.dataset;
    this.element.addEventListener("click", (e) => {
      e.preventDefault();
      e.stopPropagation();
      swal({
        title: confirm,
        buttons: {
          oui: { value: "oui", text: confirmButtonText },
          non: { value: "non", text: cancelButtonText },
        },
        className: "custom-swal-modal",
      }).then((value) => {
        if (isSwalConfirmed(value)) {
          this.element.nextElementSibling.click();
        }
      });
    });
  }
}
