import { Controller } from "@hotwired/stimulus";
import { handleHttpResponse } from "../../utils/http_utils";
import { closeModal } from "../../utils/misc";

export default class extends Controller {
  onOtpConfigurationChanged(event) {
    handleHttpResponse({
      payload: event.detail.fetchResponse.response,
      onSuccess: () => {
        closeModal(this.element.id);
      },
      onError: () => {
        return false;
      },
    });
  }
}
